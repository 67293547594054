import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSettingsState, FontSize, Ringing } from "../types/appSettingsTypes";
import { DEFAULT_FONT_SIZE, DEFAULT_RINGING, DEFAULT_VOLUME } from "../config";
import { AppSettingsResponse, SystemSettings } from "../services/api/appSettingsApi";

const initialState: AppSettingsState = {
  urlToReleaseNotesPage: "",
  urlToFAQPage: "",
  urlToFeedbackPage: "",
  dashboardEnabled: false,
  advisorBlockClientTier: 0,
  blockHighValuedClientHelpUrl: "",
  tutorialVideosUrl: "",
  tutorialLifecycleVideoUrl: "",
  autoChatMessageDelay: 5000,
  isNewVersion: false,
  isnewVersionBannerHidden: false,
  sounds: {
    ringing: DEFAULT_RINGING,
    volume: DEFAULT_VOLUME,
  },
  fonts: {
    size: DEFAULT_FONT_SIZE,
  },
  inChatMediaSendImagesEnabled: true,
  monthlyEarningsGoalWidgetEnabled: false,
  monthlyEarningsGoalWidgetLearnMoreUrl: "",
  googleAppId: "",
  fbAppId: "",
  mixpanelId: "",
  firebaseAPIKey: "",
  firebaseAppId: "",
  firebaseProjectId: "",
  firebaseSenderId: "",
  firebaseVapidKey: "",
  firestoreAPIKey: "",
  firestoreAppId: "",
  firestoreProjectId: "",
  firestoreSenderId: "",
  clientNameMaxLength: 40,
  clientNameMinLength: 0,
  wemBanner: {url:"", banner_link: ""}
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: (create) => ({
    setAppSettings: (state, action: PayloadAction<AppSettingsResponse>) => ({ ...state, ...action.payload }),
    setIsNewVersion(state, action: PayloadAction<boolean>) {
      state.isNewVersion = action.payload;
    },
    showNewVersionBanner(state) {
      state.isnewVersionBannerHidden = false;
    },
    hideNewVersionBanner(state) {
      state.isnewVersionBannerHidden = true;
    },
    reloadApp() { },
    setAppFont(state, action: PayloadAction<FontSize>) {
      state.fonts = { size: action.payload }
    },
    setAppRinging(state, action: PayloadAction<Ringing>) {
      state.sounds.ringing = action.payload;
    },
    setAppVolume(state, action: PayloadAction<number>) {
      state.sounds.volume = action.payload;
    },
    setSystemSettings(state, action: PayloadAction<SystemSettings>) {
      state.mixpanelId = action.payload.mixpanel_token;
      state.fbAppId = action.payload.fb_app_id;
      state.googleAppId = action.payload.google_app_id;
      state.firebaseAPIKey = action.payload.wem_fb_api_key;
      state.firebaseAppId = action.payload.wem_fb_app_id;
      state.firebaseProjectId = action.payload.wem_fb_project_id;
      state.firebaseSenderId = action.payload.wem_fb_sender_id;
      state.firebaseVapidKey = action.payload.wem_fb_vapid_key;
      state.firebaseSenderId = action.payload.wem_fs_sender_id;
      state.firestoreAPIKey = action.payload.wem_fs_api_key;
      state.firestoreAppId = action.payload.wem_fs_app_id;
      state.firestoreProjectId = action.payload.wem_fs_project_id;
      state.clientNameMinLength = action.payload.note_title_min_length;
      state.clientNameMaxLength = action.payload.note_title_max_length;
      state.wemBanner = action.payload.wem_banner;
    },
    loadedSoundAndFont: create.preparedReducer(
      (volume: number | null, fontSize: FontSize | null, ringing: Ringing | null) => ({ payload: { volume, fontSize, ringing } }),
      (state, action) => {
        state.sounds.volume = action.payload.volume || DEFAULT_VOLUME;
        state.sounds.ringing = action.payload.ringing || DEFAULT_RINGING;
        state.fonts.size = action.payload.fontSize || DEFAULT_FONT_SIZE;
      }
    )
  }
  ), selectors: {
    googleAppIdSelector: (state) => state.googleAppId,
    fbAppIdSelector: (state) => state.fbAppId,
    mixpanelIdSelector: (state) => state.mixpanelId,
    wemBannerSelector: (state) => state.wemBanner,
  }
});

export const {googleAppIdSelector, fbAppIdSelector, mixpanelIdSelector, wemBannerSelector } = appSettingsSlice.selectors;

export const { 
  setAppSettings, setIsNewVersion, showNewVersionBanner, hideNewVersionBanner, reloadApp, setAppFont, 
  setAppRinging, setAppVolume, loadedSoundAndFont, setSystemSettings, 
} = appSettingsSlice.actions;

export const appSettingsReducer = appSettingsSlice.reducer;