import { createAction } from "@reduxjs/toolkit";
import { GetScheduleMessageResponse, MessageExtra } from "../services/api/messagesApi";
import {
    MessageBulkSendRequestData,
    MessageTemplateData,
    ScheduledMessageFormParams
} from "../types/messageTypes";
import { NewJobMessageNotification } from "../services/api/models/NewMessageNotification";

export const showScheduledMessageForm = createAction<ScheduledMessageFormParams>("scheduler_message/show_form");

export const sendScheduledMessage = createAction("scheduled_message/send_message",
    (clientId: number, text: string, delay: number, extra: MessageExtra) => ({
        payload: {
            clientId,
            text,
            delay,
            extra
        }
    })
);

export const sendMessage = createAction("message/send_request",
    (clientId: number, content: string, couponId?: number) => ({
        payload: {
            clientId,
            content,
            couponId
        }
    })
);

export const messageSent = createAction("message/send_response",
    (clientId: number, messageAvailable: boolean, offerAvailable: boolean, offerLockReason?: string) => ({
        payload: {
            clientId,
            messageAvailable,
            offerAvailable,
            offerLockReason
        }
    })
);

export const bulkSendMessage = createAction<MessageBulkSendRequestData>("message/bulk_send_request");

export const bulkMessageSent = createAction("message/bulk_sent");

export const scheduledMessageSent = createAction("scheduled_message/message_sent",
    (clientId: number, created: string | null, scheduledDate: string | null) => ({
        payload: {
            clientId,
            created,
            scheduledDate,
        }
    })
);

export const loadScheduleMessage = createAction("scheduled_message/load_request", (clientId: number) => ({ payload: { clientId } }));

export const scheduleMessageLoaded = createAction<GetScheduleMessageResponse | null>("scheduled_message/loaded");

export const loadMessageTemplates = createAction("message_template/list_load_request");

export const messageTemplatesLoaded = createAction<MessageTemplateData[]>("message_template/list_loaded");

export const newJobMessageNotificationReceive = createAction<NewJobMessageNotification>("message/new_message_notification");

export const liveChatModeOff = createAction("message/live_chat_mode_off", (message?: string) => ({ payload: { message } }));

