import React from "react";
import { ReactSVG } from "react-svg";
import { Tooltip } from "react-tooltip";

import "./OfferButton.scss";

interface IOfferButtonProps {
    click: (a: number) => void;
    clientId: number;
    disabled: boolean;
    tooltip?: string;
}

const OfferButton: React.FC<IOfferButtonProps> = (
    props
) => {
    const onclickHandler = (e: any) => {
        e.stopPropagation();
        props.click(props.clientId);
    }
    return (
        <div className="offer-btn-wrapper">
            {props.tooltip && <Tooltip
                id="offer-btn-tooltip-top"
                place="top-start"
                className="offer-btn-tooltip"
            />}
            <button
                data-tooltip-content={props.tooltip}
                data-tooltip-id="offer-btn-tooltip-top"
                type="button"
                className="offer-btn"
                onClick={onclickHandler}
                disabled={props.disabled}
            >
                <ReactSVG src="/images/offer-btn-icon.svg" />
            </button>
        </div>
    );
};

export default OfferButton;
