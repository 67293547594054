import { StatusChangeReason } from "../services/kserver/hub";
import { AccountState, ServerUserStatus } from "../types/userTypes";
import {
  put,
  fork,
  takeEvery,
  call,
  take,
  select,
  spawn,
} from "redux-saga/effects";
import { EventChannel, eventChannel } from "redux-saga";
import { closeModal, showAlertModal } from "../slices/modalSlice";
import * as UserActions from "../slices/userSlice";
import { userStatusSelector } from "../selectors/userSelectors";
import {
  saveFontSize,
  saveRinging,
  saveVolume,
  setIsAutoChatMessageEnabled,
  setReleaseNotesViewed,
  getCurrentUser,
  CurrentUserResponse,
} from "../services/api/userApi";
import {
  API_BASE_URL,
  APP_VERSION
} from "../config";
import * as loggly from "../services/logger";
import { createNotification } from "../services/desktopNotificationService";
import { SagaIterator } from "@redux-saga/types";
import {
  closeFCMChannel,
  setAdvisorAvailable,
  setAdvisorAway
} from "./firebaseMessaging";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { handleSignOut } from "./init";
import {
  setAppFont,
  setAppRinging,
  setAppVolume,
} from "../slices/appSettingsSlice";
import { signOut } from "../slices/authorizationSlice";
import { retry } from "./extensions";
import { liveChatModeOff } from "../actions/messageActions";
import { disconnectChatProvider } from "./pubNub";
import { getDeviceId } from "../config/device_id";
import { isRingingSelector } from "../selectors/uiSelectors";
import { isInSessionSelector } from "../selectors/sessionSelectors";
import { MyAdvisorResponse } from "../services/api/models/my-advisor";
import { currentUserDetailsLoaded } from "../slices/userSlice";

let availabilityChannel: BroadcastChannel;
let availabilityEventChannel: EventChannel<string>;

function* handleSetAvailabilityRequest(
  action: ReturnType<typeof UserActions.setAvailabilityRequest>
) {
  const oldStatus: ServerUserStatus = yield select(userStatusSelector);

  if (oldStatus === action.payload.availabilityStatus) {
    return;
  }

  const isBusy: boolean = yield call(verifyUserIsBusy);
  if (isBusy) {
    yield put(
      showAlertModal(
        "Oops!",
        "We recognize you are in the middle of a reading with a client. You can try again once the reading ends.",
        "Got it"
      )
    );
    yield put(UserActions.setAvailabilitySucceeded(oldStatus));
    return;
  }

  try {
    switch (action.payload.availabilityStatus) {
      case ServerUserStatus.Available:
        const succeded: boolean = yield call(setAdvisorAvailable);

        if (!succeded) {
          return;
        }

        yield spawn(monitorForAvailability);

        yield put(
          UserActions.setAvailabilitySucceeded(ServerUserStatus.Available)
        );

        mixpanelService.trackEvent(TrackEvents.BecameAvailable);
        mixpanelService.trackEvent(TrackEvents.ChatBecameAvailable);
        break;

      case ServerUserStatus.Away:
        const response: MyAdvisorResponse | null = yield call(setAdvisorAway);
        yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Away));

        mixpanelService.trackEvent(TrackEvents.ChatBecameUnavailable);
        if (!response?.advisor.is_available) {
          mixpanelService.trackEvent(TrackEvents.BecameUnavailable);
        }
        break;
    }
  } catch (error: any) {
    loggly.error(error);
    yield put(UserActions.setAvailabilitySucceeded(oldStatus));
    yield put(
      showAlertModal(
        "",
        `Unable to change the status. Check your internet connection or try to refresh the page`,
        "OK"
      )
    );
  }
}

export function* handleAccountStateChanged(
  action: ReturnType<typeof UserActions.accountStateChanged>
) {
  switch (action.payload.state) {
    case AccountState.suspended:
      yield accountStateChangedInternal(
        "You are currently suspended from getting new sessions",
        ServerUserStatus.Suspended
      );
      break;
    case AccountState.terminated:
      yield accountStateChangedInternal(
        "Your account has been suspended or deleted"
      );
      yield take(closeModal.type);
      const isInSession: boolean = yield select(isInSessionSelector);
      if (!isInSession) {
        yield handleSignOut();
      }
      break;
    case AccountState.rejected:
      yield accountStateChangedInternal("Your profile has been rejected");
      break;
    case AccountState.needs_improvement:
      yield accountStateChangedInternal(
        "ACTION REQUIRED: Your profile looks good but needs some improvement"
      );
      break;
    default:
      yield accountStateChangedInternal();
      break;
  }
  if (action.payload.message) {
    createNotification(action.payload.message, undefined, true);
  }
}

function* accountStateChangedInternal(
  message?: string,
  status: ServerUserStatus = ServerUserStatus.Away
) {
  if (message) {
    yield put(showAlertModal("", message, "Got it"));
  }
  yield put(UserActions.setAvailabilitySucceeded(status));
}

function* handleLiveChatModeOff(action: ReturnType<typeof liveChatModeOff>) {
  yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Away));
  availabilityEventChannel?.close();
  createNotification(
    action.payload.message || "Your status has been changed to away",
    undefined,
    true,
    "live_chat_mode_off"
  );
}

function handleVisibilityChange() {
  return eventChannel((emit) => {
    const visibilityChangeAction = () => {
      emit(true);
    };

    document.addEventListener("visibilitychange", visibilityChangeAction);
    return () =>
      document.removeEventListener("visibilitychange", visibilityChangeAction);
  });
}

function handlePageHide() {
  return eventChannel((emit) => {
    const pageHideAction = () => {
      emit(true);
    };

    window.addEventListener("pagehide", pageHideAction);
    return () => window.removeEventListener("pagehide", pageHideAction);
  });
}

function* visibilityChangeWatcher(): SagaIterator {
  const channel = yield call(handleVisibilityChange);
  while (true) {
    yield take(channel);
    const status: ServerUserStatus = yield select(userStatusSelector);
    if (status !== ServerUserStatus.Available) {
      continue;
    }
    yield put(UserActions.setAvailabilityRequest(status));
    const user: CurrentUserResponse = yield call(getCurrentUser);
    yield put(currentUserDetailsLoaded(user));

    if (user.chatModeUntil !== null) {
      yield put(
        UserActions.setAvailabilitySucceeded(status, undefined, undefined, true)
      );
      continue;
    }
    availabilityEventChannel?.close();
    yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Away));
    yield put(
      showAlertModal(
        "Status changed",
        "Your status has been changed to ‘away’. If you’d like to be available, please set yourself as ‘Available’ again.",
        "Got it"
      )
    );
  }
}

function* pageHideWatcher(): SagaIterator {
  const channel = yield call(handlePageHide);
  yield take(channel);
  closeFCMChannel();
  disconnectChatProvider();

  const status: ServerUserStatus = yield select(userStatusSelector);

  if ([ServerUserStatus.Away, ServerUserStatus.Suspended].includes(status)) {
    return;
  }

  const deviceId: string = yield call(getDeviceId);

  navigator.sendBeacon(
    `${API_BASE_URL}/my/advisor/wem_tab_closed`,
    JSON.stringify({ extend_live_chat_mode: "false", device_id: deviceId })
  );
}

function* monitorForAvailability() {
  availabilityEventChannel = yield call(createAvailabilityChannel);
  while (true) {
    yield take(availabilityEventChannel);
    const isRinging: boolean = yield select(isRingingSelector);

    if (isRinging) {
      availabilityChannel?.postMessage("available");
      continue;
    }

    break;
  }

  availabilityEventChannel?.close();
  yield put(UserActions.setAvailabilitySucceeded(ServerUserStatus.Away));
  yield put(
    showAlertModal(
      "Status changed",
      'Your status has been changed to "away" as you are available in the advisor suite on another tab.',
      "Got it"
    )
  );
}

function createAvailabilityChannel() {
  return eventChannel<string>((emit) => {
    availabilityChannel = new BroadcastChannel("advisor-availability");
    availabilityChannel.postMessage("available");

    availabilityChannel.addEventListener(
      "message",
      (event: MessageEvent<string>): any => emit(event.data),
      {}
    );

    return () => {
      availabilityChannel.close();
    };
  });
}

function* handleReleaseNoteViewed() {
  yield retry(3, 10000, setReleaseNotesViewed, APP_VERSION);
}

function* handleSetAutoChatMessageEnabled(
  action: ReturnType<typeof UserActions.setAutoChatMessageEnabled>
) {
  yield retry(3, 10000, setIsAutoChatMessageEnabled, action.payload);
}

function* handleSetAppFont(action: ReturnType<typeof setAppFont>) {
  yield retry(3, 10000, saveFontSize, action.payload);
}

function* handleSetAppRinging(action: ReturnType<typeof setAppRinging>) {
  yield retry(3, 10000, saveRinging, action.payload);
}

function* handleSetAppVolume(action: ReturnType<typeof setAppVolume>) {
  yield retry(3, 10000, saveVolume, action.payload);
}

function handleSetAvailabilitySuccess(
  action: ReturnType<typeof UserActions.setAvailabilitySucceeded>
) {
  if (
    action.payload.notify &&
    action.payload.availabilityStatus === ServerUserStatus.Away
  ) {
    if (action.payload.reason === StatusChangeReason.MissedChatsInARow) {
      loggly.log("Missed 3 session in a row");
      createNotification(
        "Your online status was changed to Away because you missed 3 session requests from your clients.",
        undefined,
        true
      );
    } else {
      createNotification(
        "Your status was changed to " +
          ServerUserStatus[action.payload.availabilityStatus]
      );
    }
  }

  if (!action.payload.isVerifying) {
    loggly.log(
      "Status set to " + ServerUserStatus[action.payload.availabilityStatus]
    );
  }

  if (action.payload.availabilityStatus === ServerUserStatus.Away) {
    availabilityEventChannel?.close();
  }
}

export function* verifyUserIsBusy() {
  const user: CurrentUserResponse = yield call(getCurrentUser);
  if (!user.isBusy) {
    return false;
  }
  return true;
}

export default function* userRoot() {
  yield takeEvery(
    UserActions.setAvailabilityRequest.type,
    handleSetAvailabilityRequest
  );
  yield takeEvery(
    UserActions.setAvailabilitySucceeded.type,
    handleSetAvailabilitySuccess
  );
  yield takeEvery(
    UserActions.setReleaseNotesViewed.type,
    handleReleaseNoteViewed
  );
  yield takeEvery(setAppFont.type, handleSetAppFont);
  yield takeEvery(setAppRinging.type, handleSetAppRinging);
  yield takeEvery(setAppVolume.type, handleSetAppVolume);
  yield takeEvery(
    UserActions.setAutoChatMessageEnabled.type,
    handleSetAutoChatMessageEnabled
  );
  yield takeEvery(
    UserActions.accountStateChanged.type,
    handleAccountStateChanged
  );
  yield takeEvery(signOut.type, handleSignOut);
  yield takeEvery(liveChatModeOff.type, handleLiveChatModeOff);
  // yield fork(checkIsExpertActive);

  yield fork(pageHideWatcher);
  yield fork(visibilityChangeWatcher);
}
