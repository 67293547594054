import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  tagCounted,
  tagCounting,
  tagSelectedSave,
  countLoaded,
  countLoading,
  filterPresetWasChanged,
  gotoPage,
  pageLoaded,
  pageLoading,
  resetClientList,
  searchTextClear,
  searchTextProcess,
  searchTextStore,
  setFilterPreset,
  setPage,
  setRowsPerPage,
  setSorting,
  gotoNextPage,
  gotoPrevPage,
  openClientList,
  rowsPerPageChanged,
  setFavorite,
  clickColumnHeader,
  searchTextChanged,
  tagSelected,
  refreshPage,
} from "../slices/clientListSlice";
import { clientsByGroupsLoaded, groupMessageShowTooltip, loadClientsByGroups } from "../slices/groupMessageSlice";
import { closeModal, showModal } from "../slices/modalSlice";
import {
  onBoardingStart,
  onBoardingStartFromStep,
} from "../slices/onBoardingSlice";
import {
  CLIENT_LIST_DEFAULT_PRESET,
  DELAY_BEFOR_START_SEARCH,
  DELAY_BETWEEN_ONBOARDING_SLIDES_AND_TOOLTIPS,
  NUMBER_OF_ONBOARDING_TOOLTIPS,
  NUMBER_OF_TUTORIAL_SLIDES,
  RETRY_DELAY,
  RETRY_MAX_TRIES,
  RETRY_SHORT_DELAY,
  START_SEARCH_MIN_PATTERN_LENGTH,
} from "../config";
import {
  clientListSelector,
  requestViewPendingListSelector,
  searchTextSelector,
  sortFieldSelector,
} from "../selectors/clientListSelector";
import { onBoardingSelector } from "../selectors/onBoardingSelector";
import {
  isManagerOfVIPAccountsSelector,
  userSelector,
} from "../selectors/userSelectors";
import {
  ClientListFilterPresetEnum,
  ClientListSortFieldEnum,
  ClientListTagCountResponse,
  getClientList,
  getClientListTagCount,
  getClientsByGroups,
  SortOrder,
  updateFavorite,
} from "../services/api/clientListApi";
import * as userApi from "../services/api/userApi";
import * as loggly from "../services/logger";
import {
  ClientListState,
  ClientsByGroupsData,
  ClientListResponse,
} from "../types/clientListTypes";
import { ModalType } from "../types/modalTypes";
import { OnBoardingState } from "../types/onBoardingTypes";
import { UserState } from "../types/userTypes";
import mixpanelService, { TrackEvents } from "../services/mixpanel";
import { getEnumKeyByEnumValue } from "../helpers/utils";
import { retry } from "./extensions";

let isFirstLoad = true;

function* handleOpenClientList() {
  const currentUserDetails: UserState = yield select(userSelector);
  const isOnboardingComplete: OnBoardingState = yield select(
    onBoardingSelector
  );
  var onBoardingFlag =
    currentUserDetails.showOnboardingTooltips &&
    !isOnboardingComplete.isCompleted;

  let requestedPreset: ClientListFilterPresetEnum | null;

  if (currentUserDetails.managerOfVipAccounts) {
    requestedPreset = ClientListFilterPresetEnum.VIPClients;
  } else {
    requestedPreset = yield select(requestViewPendingListSelector);
  }

  yield put(resetClientList());

  const clientListState: ClientListState = yield select(clientListSelector);

  yield put(
    filterPresetWasChanged(
      requestedPreset ? requestedPreset : clientListState.filterPreset
    )
  );

  if (isFirstLoad) {
    isFirstLoad = false;
    if (onBoardingFlag) {
      yield put(showModal(ModalType.OnboardSlides));
      yield take(closeModal.type);
      yield delay(DELAY_BETWEEN_ONBOARDING_SLIDES_AND_TOOLTIPS);
      yield put(onBoardingStart());
    } else {
      if (
        currentUserDetails.lastViewedTutorialSlide < NUMBER_OF_TUTORIAL_SLIDES
      ) {
        yield put(
          showModal(ModalType.Slides, {
            startFrom: currentUserDetails.lastViewedTutorialSlide + 1,
          })
        );
        yield take(closeModal.type);
        yield call(
          userApi.saveLastViewedTutorialSlide,
          NUMBER_OF_TUTORIAL_SLIDES
        );
        yield delay(DELAY_BETWEEN_ONBOARDING_SLIDES_AND_TOOLTIPS);
      }

      if (
        currentUserDetails.lastViewedOnboardingTooltip <
        NUMBER_OF_ONBOARDING_TOOLTIPS
      ) {
        yield put(
          onBoardingStartFromStep(
            currentUserDetails.lastViewedOnboardingTooltip + 1
          )
        );
      }
    }
  }

  if (
    currentUserDetails.shownGroupMessages &&
    !currentUserDetails.groupMessagesTooltipViewed
  ) {
    yield put(groupMessageShowTooltip());
  }
}

var currentListRequestNumber = 0;
let previousCompletedSearch: string | null;
export function* handleGetClientList() {
  currentListRequestNumber++;
  const myNumber = currentListRequestNumber;

  yield put(pageLoading());
  const clientListState: ClientListState = yield select(clientListSelector);

  const clientListResponse: ClientListResponse = yield retry(
    RETRY_MAX_TRIES,
    RETRY_DELAY,
    getClientList,
    {
      filterPreset: clientListState.filterPreset,
      pageNumber: clientListState.currentPage,
      rowsPerPage: clientListState.rowsPerPage,
      sortField: clientListState.sortField,
      sortingOrder: clientListState.sortingOrder,
      searchText: clientListState.searchText,
      filterTag: clientListState.tags.selected,
    }
  );

  if (myNumber === currentListRequestNumber) {
    yield put(pageLoaded(clientListResponse.clients));
    previousCompletedSearch = clientListState.searchText;

    mixpanelService.trackEvent(TrackEvents.ClientListLoaded, {
      "client list name": getEnumKeyByEnumValue(
        ClientListFilterPresetEnum,
        clientListState.filterPreset
      ),
      "number of clients in list": clientListResponse.totalCount,
    });

    return clientListResponse.totalCount;
  }

  return 0;
}

function* handleGotoPage(action: ReturnType<typeof gotoPage>) {
  const clientListState: ClientListState = yield select(clientListSelector);
  const lastPage = Math.ceil(
    clientListState.count / clientListState.rowsPerPage
  );

  let pageNumber = action.payload.pageNumber;
  if (pageNumber < 1) {
    pageNumber = 1;
  }
  if (pageNumber > lastPage) {
    pageNumber = lastPage > 0 ? lastPage : 1;
  }

  yield put(setPage(pageNumber));
  if (pageNumber === 1) {
    yield put(countLoading());
    let resultLength: number = yield handleGetClientList();
    yield put(countLoaded(resultLength));
  } else {
    yield handleGetClientList();
  }
}
function* handleRefreshPage(action: ReturnType<typeof refreshPage>) {
  const clientListState: ClientListState = yield select(clientListSelector);

  if (
    clientListState.filterPreset === ClientListFilterPresetEnum.PotentialClients
  ) {
    yield handleGotoPage(gotoPage(clientListState.currentPage));
  }
}

function* handleGotoNextPage() {
  const clientListState: ClientListState = yield select(clientListSelector);
  const lastPage = Math.ceil(
    clientListState.count / clientListState.rowsPerPage
  );
  if (clientListState.currentPage < lastPage) {
    yield put(setPage(clientListState.currentPage + 1));
    yield handleGetClientList();
  }
}

function* handleGotoPrevPage() {
  const clientListState: ClientListState = yield select(clientListSelector);
  if (clientListState.currentPage > 1) {
    yield put(setPage(clientListState.currentPage - 1));
    yield handleGetClientList();
  }
}

function* handleRowsPerPageChanged(action: ReturnType<typeof rowsPerPageChanged>) {
  yield put(setRowsPerPage(action.payload.rowsPerPage));
  yield handleGotoPage(gotoPage(1));
}

function* handleFilterPresetChanged(action: ReturnType<typeof filterPresetWasChanged>) {
  loggly.log(
    `Preset ${ClientListFilterPresetEnum[action.payload.filterPreset]} was used`
  );

  yield put(setFilterPreset(action.payload.filterPreset));
  if (
    action.payload.filterPreset === ClientListFilterPresetEnum.RecentlyContacted
  ) {
    yield fork(calculateTagAmounts);
  } else {
    // reset tag count in case a request was cancelled
    yield put(tagCounted(null));
  }

  if (
    action.payload.filterPreset ===
    ClientListFilterPresetEnum.KasambaSuggestions
  ) {
    yield put(
      setSorting(
        ClientListSortFieldEnum.KasambaSuggestionsSorting,
        SortOrder.Asc
      )
    );
  }

  const sortingField: ClientListSortFieldEnum = yield select(sortFieldSelector);
  if (
    sortingField === ClientListSortFieldEnum.KasambaSuggestionsSorting &&
    action.payload.filterPreset !==
    ClientListFilterPresetEnum.KasambaSuggestions
  ) {
    yield put(
      setSorting(
        ClientListSortFieldEnum.DOBWithLastSessionEndTime, // set default sort
        SortOrder.Desc
      )
    );
  }

  yield put(gotoPage(1));

  const searchText: string | null = yield select(searchTextSelector);
  if (
    action.payload.filterPreset !== ClientListFilterPresetEnum.Search &&
    searchText
  ) {
    yield put(searchTextClear());
  }
}

function* calculateTagAmounts() {
  yield put(tagCounting());
  yield take([pageLoaded.type]);
  const tagCountCount: ClientListTagCountResponse = yield retry(
    RETRY_MAX_TRIES,
    RETRY_DELAY,
    getClientListTagCount
  );
  yield put(tagCounted(tagCountCount));
}

function* handleSetFavorite(action: ReturnType<typeof setFavorite>) {
  loggly.log(
    `Favorite was set to ${action.payload.isFavorite} for client with id ${action.payload.clientId}`
  );
  yield retry(
    RETRY_MAX_TRIES,
    RETRY_SHORT_DELAY,
    updateFavorite,
    action.payload.clientId,
    action.payload.isFavorite
  );

  mixpanelService.trackEvent(TrackEvents.FavoritesUpdated, {
    "client id": action.payload.clientId,
    "client name": action.payload.clientName,
    action: action.payload.isFavorite ? "added" : "removed",
    "editing location": action.payload.location,
  });
}

function* handleClickColumnHeader(action: ReturnType<typeof clickColumnHeader>) {
  loggly.log(
    `Sorting by column ${ClientListSortFieldEnum[action.payload.sortField]
    } was used`
  );
  const clientListState: ClientListState = yield select(clientListSelector);
  if (clientListState.sortField === action.payload.sortField) {
    yield put(
      setSorting(
        action.payload.sortField,
        clientListState.sortingOrder === SortOrder.Asc
          ? SortOrder.Desc
          : SortOrder.Asc
      )
    );
  } else {
    yield put(setSorting(action.payload.sortField, SortOrder.Desc));
  }
  yield put(gotoPage(1));
}

function* handleSearchTextChanged(action: ReturnType<typeof searchTextChanged>) {
  try {
    yield put(searchTextStore(action.payload.text));
    yield put(searchTextProcess(action.payload.text));
  } catch (e) {
    console.error(e);
  }
}

function* handleSearchTextProcess(action: ReturnType<typeof searchTextProcess>) {
  yield delay(DELAY_BEFOR_START_SEARCH);

  if (action.payload.text && previousCompletedSearch === action.payload.text) {
    return;
  }

  // don't do actual search when previous search word was startad with same letters and no data was found
  if (
    action.payload.text &&
    previousCompletedSearch &&
    !Number(action.payload.text) &&
    action.payload.text.startsWith(previousCompletedSearch)
  ) {
    const clientList: ClientListState = yield select(clientListSelector);
    if (clientList.pageData && !clientList.pageData.length) {
      return;
    }
  }

  if (action.payload.text) {
    if (action.payload.text.length >= START_SEARCH_MIN_PATTERN_LENGTH) {
      yield put(filterPresetWasChanged(ClientListFilterPresetEnum.Search));
    }
  } else {
    const managerOfVIPAccounts: boolean = yield select(
      isManagerOfVIPAccountsSelector
    );
    yield handleFilterPresetChanged(
      filterPresetWasChanged(
        managerOfVIPAccounts
          ? ClientListFilterPresetEnum.VIPClients
          : CLIENT_LIST_DEFAULT_PRESET
      )
    );
  }
}

function* handleTagSelected(action: ReturnType<typeof tagSelected>) {
  yield put(tagSelectedSave(action.payload.filterTag));
  yield put(gotoPage(1));
}

function* handleGetClientsByGroups() {
  const clientsByGroups: ClientsByGroupsData = yield retry(
    RETRY_MAX_TRIES,
    RETRY_DELAY,
    getClientsByGroups
  );
  yield put(clientsByGroupsLoaded(clientsByGroups));
}

export default function* clientListRoot() {
  yield takeEvery(openClientList.type, handleOpenClientList);
  yield takeLatest(gotoPage.type, handleGotoPage);
  yield takeLatest(gotoNextPage.type, handleGotoNextPage);
  yield takeLatest(gotoPrevPage.type, handleGotoPrevPage);
  yield takeEvery(rowsPerPageChanged.type, handleRowsPerPageChanged);
  yield takeLatest(filterPresetWasChanged.type, handleFilterPresetChanged);
  yield takeEvery(setFavorite.type, handleSetFavorite);
  yield takeEvery(clickColumnHeader.type, handleClickColumnHeader);
  yield takeEvery(searchTextChanged.type, handleSearchTextChanged);
  yield takeLatest(searchTextProcess.type, handleSearchTextProcess);
  yield takeLatest(tagSelected.type, handleTagSelected);
  yield takeLatest(refreshPage.type, handleRefreshPage);
  yield takeLatest(loadClientsByGroups.type, handleGetClientsByGroups);
}
