import { replace } from "redux-first-history";
import { all, fork, put, retry, takeEvery } from "redux-saga/effects";
import {
  dataLoading,
  dashboardDataLoaded,
  gotoDashboard,
  viewClientListPreset,
  insightDataLoaded,
} from "../slices/dashboardSlice";
import { RoutesM } from "../router";
import { getAdvisorDashboardData, getAdvisorDashboardInsightData } from "../services/api/dashboardApi";

import * as loggly from "../services/logger";
import { requestViewPendingList } from "../slices/clientListSlice";
import { AdvisorDashboardDataResponse } from "../services/api/models/my-advisor-dashboard";
import { InsightApiResponse } from "../services/api/models/insight";

function* handleOpenDashboard() {
  yield put(replace(RoutesM.Dashboard));

  loggly.log("Dashboard: opening ");

  yield put(dataLoading());

  yield fork(loadDashboardData);
}

function* loadDashboardData() {
  const [dashboardData,
    dashboardInsightData]: [
      AdvisorDashboardDataResponse,
      InsightApiResponse
  ] = yield all([
    retry(3, 10000, getAdvisorDashboardData),
    retry(3, 10000, getAdvisorDashboardInsightData),
  ]);
  
  yield put(insightDataLoaded(dashboardInsightData));
  yield put(dashboardDataLoaded(dashboardData));

}

function* handleViewClientListPreset(
  action: ReturnType<typeof viewClientListPreset>
) {
  if (action.payload) {
    yield put(requestViewPendingList(action.payload));
    loggly.log(`Dashboard: opening clientlist preset ${action.payload}`);
  }
  yield put(replace(RoutesM.Clients));
}

export default function* dashboardRoot() {
  yield takeEvery(gotoDashboard.type, handleOpenDashboard);
  yield takeEvery(viewClientListPreset.type, handleViewClientListPreset);
}
