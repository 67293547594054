import "./ClientListTableRow.css";
import { ClientListData } from "../../../types/clientListTypes";
import { kasambaFormatDate } from "../../../helpers/dateFormatHelper";
import { LastReadingCell } from "../../atoms/ClientList/LastReadingCell";
import ScheduleButton from "../../atoms/ClientList/ScheduleButton";
import FavoriteIcon from "../../atoms/FavoriteIcon";
import { ClientCardTab, EditTitleFields } from "../../../types/clientTypes";
import { DobCellWithAdd } from "./DobCellWithAdd";
import GenderIcon from "../../atoms/GenderIcon";
import OfferButton from "../../atoms/buttons/OfferButton";
import { AccountState } from "../../../types/userTypes";
import { getOfferStateAndTooltip } from "../../../helpers/clientListHelper";
import { getRemoteConfigValue, RemoteConfigKeys } from "../../../config";

export interface IClientListTableRowProps {
  data: Array<ClientListData>;
  scheduleMessageClick: (clientId: number) => void;
  couponBtnClick: (clientId: number, clientName: string) => void;
  updateFavorite: (
    cleintId: number,
    isFavorite: boolean,
    clientName: string
  ) => void;
  onSelectClient: (
    clientId: number,
    tab: ClientCardTab | null,
    setFocusOnField: EditTitleFields | null
  ) => void;
  selectedClientId: number | null;
  isImpersonated: boolean;
  accountState: AccountState;
  couponsDaysToNextOffer: number;
}


export function ClientListTableRow(props: IClientListTableRowProps) {

  function rowClickHandler(
    client: ClientListData,
    setFocusOnField: EditTitleFields | null
  ) {
    if (props.selectedClientId == client.clientId) {
      return;
    }
    let tab: ClientCardTab | null = null;
    if (client.dobOrder < 3 && setFocusOnField === null) {
      tab = ClientCardTab.Messages;
    }
    props.onSelectClient(
      client.clientId,
      ClientCardTab.Notes /*TODO uncomment after dob will be correct -> tab*/,
      setFocusOnField
    );
  }

  const disableCoupons = getRemoteConfigValue(RemoteConfigKeys.disableCoupons).asBoolean();

  function rowgenerator() {
    let items = [];

    for (let i = 0; i < props.data.length; i++) {
      const item = props.data[i];
      const [ offerDisabled, offerTooltip ] = getOfferStateAndTooltip(props.accountState, props.couponsDaysToNextOffer, item.offerAvailable, item.offerLockReason);
      items.push(
        <tr
          onClick={() => rowClickHandler(item, null)}
          className={
            "cl-data-row " +
            (props.selectedClientId == item.clientId
              ? "selected "
              : "") +
            (item.isScheduledMessageEnabled
              ? "cl-data-row-withbtn"
              : item.lastSessionEndTime
                ? "cl-data-row-wobtn"
                : "cl-data-row-woread") +
            (item.hasUnreadMessages ? " cl-data-row-unread" : "")
          }
          key={i}
        >
          <td className="fav-icon-td">
            <FavoriteIcon
              isFavoriteClient={item.isFavoriteClient}
              isImpersonated={props.isImpersonated}
              onclick={() => {
                props.updateFavorite(
                  item.clientId,
                  !item.isFavoriteClient,
                  item.clientAlias
                );
              }}
            />
          </td>
          <td className="td-client-name">
            {item.clientNameFromNote}
            {!item.clientNameFromNote && (
              <span
                className="add-text"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSelectClient(
                    item.clientId,
                    ClientCardTab.Notes,
                    EditTitleFields.Name
                  );
                }}
              >
                + Add
              </span>
            )}
          </td>
          <td className="td-client-alias">{item.clientAlias}</td>
          <td className="td-client-gender">
            <GenderIcon gender={item.gender} />
          </td>
          <td className="td-client-dob">
            <DobCellWithAdd
              birthDate={item.dob || item.givenDob}
              dobOrder={item.dobOrder}
              onClickHandler={(e) => {
                e.stopPropagation();
                props.onSelectClient(
                  item.clientId,
                  ClientCardTab.Notes,
                  EditTitleFields.DOB
                );
              }}
            />
          </td>
          <td className="td-client-id">{item.clientId}</td>
          {/* <td className="td-client-tag">
            <TagClient tag={item.tag} />
          </td> */}
          <td className="td-session-count">{item.sessionCount}</td>
          <td className="td-last-reading">
            <LastReadingCell
              lastReadDate={item.lastSessionEndTime}
              lastSessionType={item.lastSessionType}
            />
          </td>
          <td className="td-instant-message">
            {item.hasUnreadMessages && (
              <img
                className="unread-messages-icon"
                src="/images/unread-messages.svg"
                alt="unread messages icon"
              />
            )}
            {item.hasUnreadMessages && !item.lastInstantMessage && "New"}
            {item.lastInstantMessage && kasambaFormatDate(item.lastInstantMessage)}
          </td>
          <td className="td-scheduled-message">
            <ScheduleButton
              click={() =>
                props.onSelectClient(
                  item.clientId,
                  ClientCardTab.Messages,
                  null
                )
              }
              rowid={i}
            />
          </td>
          {!disableCoupons && <td className="td-offers">
            <OfferButton click={() => props.couponBtnClick(item.clientId, item.clientAlias)} clientId={i} disabled={offerDisabled} tooltip={offerTooltip} />
          </td>}
        </tr>
      );
    }
    return items;
  }

  return <>{rowgenerator()}</>;
}
