import { SessionType } from "../types/clientListTypes";
import { AccountState } from "../types/userTypes";

export function getSessionType(value: string): SessionType {
  switch (value) {
    case "voice":
    case "voice call":
      return SessionType.Voice;
    case "chat":
      return SessionType.Chat;
    case "video call":
    case "video":
      return SessionType.Video;
    case "test":
    case "rush":
    case "regular":
    case "offline":
    default:
      return SessionType.NA;
  }
}

export const getOfferStateAndTooltip = (accountState: AccountState, couponsDaysToNextOffer: number, offerAvailable: boolean, offerLockReason?: string): [boolean, string | undefined] => {
  if ([AccountState.suspended, AccountState.rejected].includes(accountState)) {
    return [true, "You're unable to send offers at this time. Please contact support for more information."];
  }

  if (accountState === AccountState.new) {
    return [true, "The ability to send offers will be connected to the ability to provide live services (chat, voice, video)."];
  }

  if (offerAvailable) {
    return [false, undefined];
  }

  if (offerLockReason === 'temporary') {
    return [true, `You recently sent an offer to this client. To send another one, wait ${couponsDaysToNextOffer} days after the current offer expires or ${couponsDaysToNextOffer} days after the client redeems it.`];
  }

  return [true, "You're unable to send offers at this time."]
};
