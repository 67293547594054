import ReactModal from 'react-modal';
import { DiscountHorizontalMenu } from '../organisms/DiscountHorizontalMenu';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeModal } from '../../slices/modalSlice';
import { AppState } from '../../store';
import { sendMessage } from '../../actions/messageActions';

import './SelectCouponModal.scss';

const SelectCouponModal: React.FC<PropsFromRedux> = ({ closeModal, coupons, messageNote, advisorName, clientId, clientName, sendMessage }: PropsFromRedux) => {
    const options = coupons
        .filter(x => x.active)
        .sort((a, b) => a.discount_percent - b.discount_percent)
        .map(x => ({ id: x.id, percent: x.discount_percent }));

    const defaultMessage: string = "Hey {0}!\r\nAs a thank you for our meaningful connection, I'd like to offer you {1}% off on our next session.\r\n\r\n{2}";

    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [message, setMessage] = useState(defaultMessage.format(clientName, options[selectedItem].percent, advisorName));
    
    useEffect(() => {
        setMessage(defaultMessage.format(clientName, options[selectedItem].percent, advisorName));
    }, [ selectedItem ])

    const handleSendPromotion = () => {
        setIsLoading(true);
        sendMessage(clientId, message, options[selectedItem].id);
    };

    return <ReactModal
        isOpen={true}
        overlayClassName="modal-overlay"
        className="modal-content select-coupon-modal"
    >
        <div className="close" onClick={() => closeModal()}></div>
        <div className="header">
            <h3>Send a one-time offer</h3>
            <p>If you happen to change your rate, the offer value will adjust accordingly.</p>
        </div>

        <div className="discount-block">
            <h5>Select offer value</h5>
            <DiscountHorizontalMenu options={options.map(x=>x.percent)} selectedIndex={selectedItem} onClick={(index) => setSelectedItem(index)} />
        </div>

        <div className="message-block">
            <h5>Add a message</h5>

            <div className="message-area-wrapper">
                <textarea className="message-area" value={ message } onChange={(e) => setMessage(e.target.value)} />
                <div className="separator" />
                <div className="expiry-info">{messageNote}</div>
            </div>
        </div>

        <button type="button" disabled={isLoading} className="send-promotion-btn" onClick={handleSendPromotion}>Send Promotion</button>
    </ReactModal>
}

const mapState = (state: AppState) => ({
    coupons: state.user.coupons,
    messageNote: state.user.couponsMessageNote,
    advisorName: state.user.userName,
    clientName: state.modal.props.clientName,
    clientId: state.modal.props.clientId
});

const connector = connect(mapState, { closeModal, sendMessage });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SelectCouponModal);