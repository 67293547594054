import { createSlice } from "@reduxjs/toolkit";
import { AccountState, ServerUserStatus, UserState } from "../types/userTypes";
import {
  ClientSettingsResponse,
  CurrentUserResponse,
  DiscountCoupon,
} from "../services/api/userApi";
import { StatusChangeReason } from "../services/kserver/hub";

const initialState: UserState = {
  userId: null,
  userName: "",
  userEmail: "",
  userFee: 0,
  userAvatarUrl: "",
  isSetStatusRequested: false,
  serverUserStatus: ServerUserStatus.Away,
  showOnboardingTooltips: false,
  showReleaseNotesMark: false,
  viewedReadingIds: [],
  messageTemplates: [],
  isMessageTemplatesLoading: false,
  lastViewedOnboardingTooltip: 0,
  managerOfVipAccounts: false,
  lastViewedTutorialSlide: 0,
  isAutoChatMessageEnabled: false,
  autoChatMessageText: "",
  isReconnection: false,
  shownGroupMessages: false,
  groupMessagesTooltipViewed: false,
  fcmToken: null,
  advisorBalanceUrl: null,
  loggerSecret: "",
  loggerEndpoint: "",
  isImpersonated: false,

  advisorId: null,
  advisorModes: [],
  creditBalance: null,
  currentPromotion: "",
  emailAddress: "",
  gender: "",
  language: "",
  lastOrderDate: "",
  lastPurchaseDate: "",
  signupDate: "",
  ordersCount: null,
  purchasesCount: null,
  totalPurchaseFeesPaid: null,
  totalPurchased: null,
  score: 0,
  state: AccountState.new,
  coupons: [],
  couponsMessageNote: "",
  couponsDaysToNextOffer: 0
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: (create) => ({
    setAvailabilityRequest: create.preparedReducer(
      (
        availabilityStatus: ServerUserStatus.Available | ServerUserStatus.Away,
        isManual: boolean = true
      ) => ({ payload: { availabilityStatus, isManual } }),
      (state) => {
        state.isSetStatusRequested = true;
      }
    ),
    setAvailabilitySucceeded: create.preparedReducer(
      (
        availabilityStatus: ServerUserStatus,
        notify: boolean = false,
        reason?: StatusChangeReason,
        isVerifying: boolean = false
      ) => ({ payload: { availabilityStatus, notify, reason, isVerifying } }),
      (state, action) => {
        state.isSetStatusRequested = false;
        state.serverUserStatus = action.payload.availabilityStatus;
      }
    ),
    accountStateChanged: create.preparedReducer(
      (state: AccountState, message: string) => ({
        payload: { state, message },
      }),
      (state, action) => {
        state.state = action.payload.state;
      }
    ),
    currentUserDetailsLoaded: create.preparedReducer(
      (data: CurrentUserResponse) => ({
        payload: {
          id: data.id,
          name: data.name,
          email: data.email,
          fee: data.fee,
          avatarUrl: data.avatarUrl,
          showOnboardingTooltips: data.showOnboardingTooltips,
          showReleaseNotesMark: data.showReleaseNotesMark,
          lastViewedOnboardingTooltip: data.lastViewedOnboardingTooltip || 0,
          lastViewedTutorialSlide: data.lastViewedTutorialSlide || 0,
          managerOfVipAccounts: data.managerOfVipAccounts,
          isAutoChatMessageEnabled: data.isAutoChatMessageEnabled,
          autoChatMessageText: data.autoChatMessageText,
          shownGroupMessages: data.shownGroupMessages,
          groupMessagesTooltipViewed: data.groupMessagesTooltipViewed,
          advisorBalanceUrl: data.advisorBalanceUrl,
          state: data.state,

          advisorId: data.advisorId,
          advisorModes: data.advisorModes,
          creditBalance: data.creditBalance,
          currentPromotion: data.currentPromotion,
          emailAddress: data.emailAddress,
          gender: data.gender,
          language: data.language,
          lastOrderDate: data.lastOrderDate,
          lastPurchaseDate: data.lastPurchaseDate,
          ordersCount: data.ordersCount,
          purchasesCount: data.purchasesCount,
          totalPurchaseFeesPaid: data.totalPurchaseFeesPaid,
          totalPurchased: data.totalPurchased,
          signupDate: data.signupDate,
          score: data.score,
        },
      }),
      (state, action) => {
        state.userId = action.payload.id;
        state.userName = action.payload.name;
        state.userEmail = action.payload.email;
        state.userFee = action.payload.fee;
        state.userAvatarUrl = action.payload.avatarUrl;
        state.showOnboardingTooltips = action.payload.showOnboardingTooltips;
        state.showReleaseNotesMark = action.payload.showReleaseNotesMark;
        state.lastViewedOnboardingTooltip =
          action.payload.lastViewedOnboardingTooltip;
        state.managerOfVipAccounts = action.payload.managerOfVipAccounts;
        state.lastViewedTutorialSlide = action.payload.lastViewedTutorialSlide;
        state.isAutoChatMessageEnabled =
          action.payload.isAutoChatMessageEnabled;
        state.autoChatMessageText = action.payload.autoChatMessageText;
        state.shownGroupMessages = action.payload.shownGroupMessages;
        state.groupMessagesTooltipViewed =
          action.payload.groupMessagesTooltipViewed;
        state.advisorBalanceUrl = action.payload.advisorBalanceUrl;
        state.state = action.payload.state;

        state.advisorId = action.payload.advisorId;
        state.advisorModes = action.payload.advisorModes;
        state.creditBalance = action.payload.creditBalance;
        state.currentPromotion = action.payload.currentPromotion;
        state.emailAddress = action.payload.emailAddress;
        state.gender = action.payload.gender;
        state.language = action.payload.language;
        state.lastOrderDate = action.payload.lastOrderDate;
        state.lastPurchaseDate = action.payload.lastPurchaseDate;
        state.ordersCount = action.payload.ordersCount;
        state.purchasesCount = action.payload.purchasesCount;
        state.totalPurchaseFeesPaid = action.payload.totalPurchaseFeesPaid;
        state.totalPurchased = action.payload.totalPurchased;
        state.signupDate = action.payload.signupDate;
        state.score = action.payload.score;
      }
    ),
    clientSettingsLoaded: create.preparedReducer(
      (data: ClientSettingsResponse) => ({
        payload: {
          loggerEndpoint: data.mba_logger_endpoint,
          loggerSecret: data.mba_logger_secret,
          coupons: data.ui_settings.discount_coupons,
          couponsMessageNote: data.ui_settings.discount_coupon_message_note,
          couponsDaysToNextOffer: data.ui_settings.discount_coupon_days_to_next_offer
        },
      }),
      (state, action) => {
        state.loggerEndpoint = action.payload.loggerEndpoint;
        state.loggerSecret = action.payload.loggerSecret;
        state.coupons = action.payload.coupons;
        state.couponsMessageNote = action.payload.couponsMessageNote;
        state.couponsDaysToNextOffer = action.payload.couponsDaysToNextOffer;
      }
    ),
    setReleaseNotesViewed(state) {
      state.showReleaseNotesMark = false;
    },
    setReadingViewed: create.preparedReducer(
      (sessionId: number) => ({ payload: { sessionId } }),
      (state, action) => {
        state.viewedReadingIds.push(action.payload.sessionId);
      }
    ),
    setAutoChatMessageEnabled: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        state.isAutoChatMessageEnabled = action.payload;
      }
    ),
    setAvailabilityFail(state) {
      state.isSetStatusRequested = false;
    },
    setReconnectingValue: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        state.isReconnection = action.payload;
      }
    ),
    setIsImpersonated: create.preparedReducer(
      (value: boolean) => ({ payload: value }),
      (state, action) => {
        state.isImpersonated = action.payload;
      }
    ),
    setCoupons: create.preparedReducer(
      (coupons: DiscountCoupon[]) => ({ payload: coupons }),
      (state, action) => {
        state.coupons = action.payload;
      }
    ),
  }),
  selectors: {
    advisorBalanceUrlSelector: (state) => state.advisorBalanceUrl,
    accountStateSelector: (state) => state.state,
    isImpersonatedSelector: (state) => state.isImpersonated,
    userIdSelector: (state) => state.userId,
  },
});

export const { advisorBalanceUrlSelector, accountStateSelector, isImpersonatedSelector, userIdSelector } =
  userSlice.selectors;

export const {
  setAvailabilityRequest,
  setAvailabilitySucceeded,
  currentUserDetailsLoaded,
  clientSettingsLoaded,
  setReleaseNotesViewed,
  setReadingViewed,
  setAutoChatMessageEnabled,
  setAvailabilityFail,
  setReconnectingValue,
  accountStateChanged,
  setIsImpersonated,
  setCoupons
} = userSlice.actions;
export const userReducer = userSlice.reducer;
