import * as React from "react";
// TODO: upgrade v18
//import { defaultProps } from "react-select/src/Select";
import { ClientListSortFieldEnum } from "../../../services/api/clientListApi";
import OnBoardingToolTip from "../OnBoardingToolTip";
import "./ClientListTableHeader.css";
import { SortingIcon } from "./SortingIcon";
import { TableHeaderCell } from "./TableHeaderCell";
import { textManager } from "../../../config/textManager";
import { getRemoteConfigValue, RemoteConfigKeys } from "../../../config";

export interface IClientListTableHeaderProps {
  clickColumnHeader: (field: ClientListSortFieldEnum) => void;
  sortField: ClientListSortFieldEnum;
  sortingOrder: string;
}
export const ClientListTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  IClientListTableHeaderProps
>(
  (props: IClientListTableHeaderProps, ref) => {
    const disableCoupons = getRemoteConfigValue(RemoteConfigKeys.disableCoupons).asBoolean();
    return (
      <>
        <colgroup>
          <col className="col-faivorite" />
          <col className="col-client-name" />
          <col className="col-client-alias" />
          <col className="col-client-gender" />
          <col className="col-client-dob" />
          <col className="col-client-id" />
          <col className="col-session-count" />
          <col className="col-last-reading" />
          <col className="col-instant-message" />
          <col className="col-actions" />
          {!disableCoupons && <col className="col-offers" />}
        </colgroup>
  
        <thead ref={ref}>
          <tr className="cl-list-header">
            <TableHeaderCell
              // clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.IsFavoriteClient}
              title=""
            />
            <TableHeaderCell
              // clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.ClientNameFromNote}
              title="NAME"
            />
            <TableHeaderCell
              clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.ClientAlias}
              title="NICKNAME"
            />
            <TableHeaderCell
              // clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.Gender}
              title="GENDER"
            />
            <th className="cl-list-header-dob">
              <OnBoardingToolTip
                title="NEW! CLIENT DATE OF BIRTH"
                body="Add your client date of birth on your client card note title and get notified when they have a birthday."
                step={5}
                placement="top-end"
                appendTo={document.body}
                width="215px"
              >
                <span>DOB</span>
              </OnBoardingToolTip>
            </th>
  
            <TableHeaderCell
              clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.ClientId}
              title="CLIENT ID"
            />
            {/* <TableHeaderCell
              // clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.ClientTag}
              title="STATUS"
            /> */}
            <TableHeaderCell
              clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.SessionCount}
              title={textManager.clientListTableHeader.sessions}
            />
  
            <TableHeaderCell
              // clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.LastSessionEndTime}
              title={textManager.clientListTableHeader.lastReading}
            />
  
            <TableHeaderCell
              clickColumnHeader={props.clickColumnHeader}
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              field={ClientListSortFieldEnum.LastInstantMessage}
              title="LAST MESSAGE"
            />
  
            <th
            // onClick={() =>
            //   props.clickColumnHeader(ClientListSortFieldEnum.IsScheduledMessage)
            // }
            >
              {props.sortField === ClientListSortFieldEnum.IsScheduledMessage && (
                <SortingIcon sortingOrder={props.sortingOrder} />
              )}
              <OnBoardingToolTip
                title="SEND A SCHEDULED MESSAGE"
                body="Easier than ever - just choose your client from the list and set up your scheduled message."
                step={3}
                placement="top-start"
                appendTo={document.body}
                width="215px"
              >
                <span>ACTIONS</span>
              </OnBoardingToolTip>
            </th>
            {!disableCoupons && <TableHeaderCell
              currentSortField={props.sortField}
              sortingOrder={props.sortingOrder}
              title="OFFERS"
            />}
  
          </tr>
        </thead>
      </>
    );
  }

);
