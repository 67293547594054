import './DiscountCard.scss';

type Props = {
    percent: number;
    selected: boolean;
    thinVersion?: boolean;
    showCheckMark?: boolean;
    onClick?: VoidFunction;
};

type PropsSlim = {
    percent: number;
};

export const DiscountCard = ({ percent, onClick, selected, showCheckMark = false }: Props) => {
    return <div className={`discount-card${selected ? ' selected' : ''}${showCheckMark ? ' with-mark' : ''}`} onClick={onClick}>
        {selected && <svg width="144" height="90" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M40 7C42.8278 7 45.264 5.32321 46.3685 2.90966C47.0579 1.40307 48.3431 0 50 0H134C139.523 0 144 4.47715 144 10V80C144 85.5228 139.523 90 134 90H50C48.3431 90 47.0579 88.5969 46.3685 87.0903C45.264 84.6768 42.8278 83 40 83C37.1722 83 34.736 84.6768 33.6315 87.0903C32.9421 88.5969 31.6569 90 30 90H10C4.47715 90 0 85.5229 0 80V10C0 4.47715 4.47715 0 10 0H30C31.6569 0 32.9421 1.40307 33.6315 2.90966C34.736 5.32321 37.1722 7 40 7Z"
                fill={selected ? "url(#selected-gradient)" : "none"} />
            <defs>
                <linearGradient id="selected-gradient" x1="-1.53801e-06" y1="10" x2="148.94" y2="48.0156" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--discount-gradient-first)" />
                    <stop offset="1" stopColor="var(--discount-gradient-second)" />
                </linearGradient>
            </defs>
        </svg>
        }
        {!selected && <svg width="144px" height="90px" viewBox="0 0 144 90" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_2398_19666)" transform="matrix(0.986111, 0, 0, 0.977778, -10, 0)" id="object-0"><path fillRule="evenodd" clipRule="evenodd" d="M51 8C53.8278 8 56.264 6.32321 57.3685 3.90966C58.0579 2.40307 59.3431 1 61 1H145C150.523 1 155 5.47715 155 11V81C155 86.5228 150.523 91 145 91H61C59.3431 91 58.0579 89.5969 57.3685 88.0903C56.264 85.6768 53.8278 84 51 84C48.1722 84 45.736 85.6768 44.6315 88.0903C43.9421 89.5969 42.6569 91 41 91H21C15.4772 91 11 86.5229 11 81V11C11 5.47715 15.4772 1 21 1H41C42.6569 1 43.9421 2.40307 44.6315 3.90966C45.736 6.32321 48.1722 8 51 8Z" fill="white" /><path fillRule="evenodd" clipRule="evenodd" d="M51 8C53.8278 8 56.264 6.32321 57.3685 3.90966C58.0579 2.40307 59.3431 1 61 1H145C150.523 1 155 5.47715 155 11V81C155 86.5228 150.523 91 145 91H61C59.3431 91 58.0579 89.5969 57.3685 88.0903C56.264 85.6768 53.8278 84 51 84C48.1722 84 45.736 85.6768 44.6315 88.0903C43.9421 89.5969 42.6569 91 41 91H21C15.4772 91 11 86.5229 11 81V11C11 5.47715 15.4772 1 21 1H41C42.6569 1 43.9421 2.40307 44.6315 3.90966C45.736 6.32321 48.1722 8 51 8Z" stroke="#D9D9D9" /></g><defs><filter id="filter0_d_2398_19666" x="0.5" y="0.5" width="165" height="111" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="10" /><feGaussianBlur stdDeviation="5" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" /><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2398_19666" /><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2398_19666" result="shape" /></filter></defs></svg>
        }
        <p className="value">{percent}% OFF</p>
        <p className="subtitle">
            your next session
        </p>
    </div>
}

export const DiscountCardSlim = ({ percent }: PropsSlim) => {
    return <div className='discount-card slim'>
        <svg width="175" height="78" viewBox="0 0 175 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M40 7.5C42.8278 7.5 45.264 5.82321 46.3685 3.40966C47.0579 1.90307 48.3431 0.5 50 0.5H165C170.523 0.5 175 4.97715 175 10.5V67.5C175 73.0228 170.523 77.5 165 77.5H50C48.3431 77.5 47.0579 76.0969 46.3685 74.5903C45.264 72.1768 42.8278 70.5 40 70.5C37.1722 70.5 34.736 72.1768 33.6315 74.5903C32.9421 76.0969 31.6569 77.5 30 77.5H10C4.47715 77.5 0 73.0229 0 67.5V10.5C0 4.97715 4.47715 0.5 10 0.5H30C31.6569 0.5 32.9421 1.90307 33.6315 3.40966C34.736 5.82321 37.1722 7.5 40 7.5Z" fill="url(#paint0_linear_2535_14269)" />
            <defs>
                <linearGradient id="paint0_linear_2535_14269" x1="-1.07586e-06" y1="10.5" x2="180.795" y2="32.6055" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--discount-gradient-first)" />
                    <stop offset="1" stopColor="var(--discount-gradient-second)" />
                </linearGradient>
            </defs>
        </svg>
        <p className="value">{percent}% OFF</p>
        <p className="subtitle">
            your next session
        </p>
    </div>
}

