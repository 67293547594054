import { replace } from "redux-first-history";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { RoutesM } from "../router";
import app from "./app";
import chat from "./chat";
import client from "./client";
import clientList from "./clientList";
import dashboard from "./dashboard";
import revenue from "./revenue";
import groupMessage from "./groupMessage";
import init, {
  handleForgotPassword,
  handleResetPassword,
  handleSignIn,
} from "./init";
import log from "./log";
import message from "./message";
import note from "./note";
import onBoarding from "./onBoarding";
import session from "./session";
import pubNub from "./pubNub";
import url from "./url";
import user from "./user";
import { SagaIterator } from "@redux-saga/types";
import { getMyAccount, signOut } from "../services/api/userApi";
import mixpanel from "mixpanel-browser";
import {
  signInSuccess,
  signIn as signInAction,
  forgotPassword,
  sendResetPassword,
} from "../slices/authorizationSlice";
import {
  SystemSettings,
  getSystemSettings,
} from "../services/api/appSettingsApi";
import { setSystemSettings } from "../slices/appSettingsSlice";
import { MyAccountResponse } from "../services/api/models/loginResponse";
import axiosInstance from "../services/api/api";
import { setIsImpersonated } from "../slices/userSlice";
import { store } from "../store";
import { AxiosHeaders, AxiosResponse } from "axios";
import { setFirebase } from "./firebaseMessaging";
import * as loggly from '../services/logger';

const interceptor = (response: AxiosResponse<any, any>) => {
  const impresonatedHeader = (response.headers as AxiosHeaders).get("X-Impersonated");
  if (impresonatedHeader) {
    store.dispatch(
      setIsImpersonated(impresonatedHeader === "true")
    );
  }
  if (response.data.analytic_events && impresonatedHeader !== "true") {
    for (const key of Object.keys(response.data.analytic_events)) {
      mixpanel.track(key, response.data.analytic_events[key]);
      loggly.log('tracked mixpanel event from the backend response', { response: response.data });
    }
  }

  return response;
};

export default function* root(): SagaIterator {
  yield takeLatest(signInSuccess.type, initApp);

  yield takeLatest(forgotPassword.type, handleForgotPassword);
  yield takeLatest(sendResetPassword.type, handleResetPassword);

  yield takeLatest(signInAction.type, handleSignIn);

  try {
    axiosInstance.interceptors.response.use(interceptor, (error) => {
      return Promise.reject(error);
    });

    const settings: SystemSettings = yield call(getSystemSettings);
    yield put(setSystemSettings(settings));

    const user: MyAccountResponse = yield call(getMyAccount);

    if (!user.user.is_advisor) {
      yield call(signOut);
      yield put(replace(RoutesM.SignIn));
    } else {
      yield put(signInSuccess());
    }
  } catch (e: any) {
    if (
      e.isAxiosError &&
      (e.response?.status === 401 || e.response?.status === 403)
    ) {
      yield put(replace(RoutesM.SignIn));
    }
  }
}

function* initApp(): any {
  var result = yield init();

  if (!result) return;

  yield call(setFirebase);
  yield put(replace(RoutesM.Clients));

  //TODO: temporary solution, needs to be replaced when API is ready
  // const isTermsAccepted = Boolean(localStorage.getItem("terms-accepted"));
  // if (!isTermsAccepted) {
  //   yield put(showModal(ModalType.AcceptNewTermsAndPolicies));
  // }
  //END

  yield all([
    // fork(postponeKServerConnection),
    fork(user),
    fork(pubNub),
    fork(session),
    fork(chat),
    fork(note),
    fork(onBoarding),
    fork(clientList),
    fork(message),
    fork(app),
    fork(client),
    fork(dashboard),
    fork(log),
    fork(groupMessage),
    fork(revenue),
  ]);

  yield fork(url);
}
