import { DiscountCoupon } from "../services/api/userApi";
import { MessageTemplateData } from "./messageTypes";

export enum ServerUserStatus {
  Away = 0,
  Available = 1,
  Busy = 2,
  Suspended = 3,
}

export enum AccountState {
  new = "new",
  pending = "pending",
  approved = "approved",
  needs_improvement = "needs_improvement",
  rejected = "rejected",
  suspended = "suspended",
  terminated = "terminated",
}

export interface UserState {
  userId: number | null;
  userName: string;
  userEmail: string;
  userAvatarUrl: string;
  userFee: number;
  serverUserStatus: ServerUserStatus;
  isSetStatusRequested: boolean;
  showOnboardingTooltips: boolean;
  showReleaseNotesMark: boolean;
  viewedReadingIds: number[];
  messageTemplates: MessageTemplateData[];
  isMessageTemplatesLoading: boolean;
  lastViewedOnboardingTooltip: number;
  managerOfVipAccounts: boolean;
  lastViewedTutorialSlide: number;
  isAutoChatMessageEnabled: boolean;
  autoChatMessageText: string;
  isReconnection: boolean;
  shownGroupMessages: boolean;
  groupMessagesTooltipViewed: boolean;
  fcmToken: string | null;
  advisorBalanceUrl: string | null;
  loggerSecret: string;
  loggerEndpoint: string;
  state: AccountState;
  isImpersonated: boolean;

  advisorId: number | null;
  advisorModes: string[];
  creditBalance: number | null;
  currentPromotion: string | null;
  emailAddress: string;
  gender: string;
  language: string;
  lastOrderDate: string;
  lastPurchaseDate: string;
  signupDate: string;
  ordersCount: number | null;
  purchasesCount: number | null;
  totalPurchaseFeesPaid: number | null;
  totalPurchased: number | null;
  score: number;
  coupons: DiscountCoupon[];
  couponsMessageNote: string;
  couponsDaysToNextOffer: number;
}
