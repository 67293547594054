import "./TopBar.css";
import { SettingsButton } from "../atoms/SettingsButton";
import ExpertStatus from "../molecules/ExpertStatus";
import OnBoardingToolTip from "../atoms/OnBoardingToolTip";
import Settings from "./Settings";
import useComponentVisible from "../../helpers/hooks";
import TopBarMenu from "../molecules/TopBarMenu";
import { AppState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import {
  gotoDashboard, viewClientListPreset,
} from "../../slices/dashboardSlice";
import { RoutesM } from "../../router";
import { dashboardEnabledSelector } from "../../selectors/appSettingsSelector";
import { isManagerOfVIPAccountsSelector } from "../../selectors/userSelectors";
import { CURRENT_SITE_ID, HELP_LINK } from "../../config";
import HelpWidgetIcon from "../atoms/HelpWidgetIcon";
import TopBarMenuItem from "../atoms/TopBarMenuItem";
import { gotoRevenue } from "../../slices/revenueSlice";
import { isInSessionSelector } from "../../selectors/sessionSelectors";

const TopBar = (props: PropsFromRedux) => {
  const {
    ref,
    isComponentVisible: isSettingsOpen,
    setIsComponentVisible: setIsSettingsOpen,
  } = useComponentVisible(false);

  return (
    <div className="topBar">
      <div className="logo">
        <img
          alt=""
          src={`/images/logo-${CURRENT_SITE_ID?.toLowerCase()}.svg`}
        />
      </div>
      <div className="top-bar--right-menu-holder">
        {/* removed by https://ingenio.atlassian.net/browse/IM-151
        {props.tutorialVideosUrl && (
          <a
            href={props.tutorialVideosUrl}
            target="_blank"
            className="tutorial-video"
          >
            <i></i>
            Watch our guide videos
          </a>
        )} */}
        {!props.isManagerOfVIPAccounts &&
          props.dashboardEnabled &&
          (RoutesM.Clients === props.currentPage ||
            RoutesM.Dashboard === props.currentPage ||
            RoutesM.Revenue === props.currentPage) && (
            <TopBarMenu>
              <TopBarMenuItem
                isActive={RoutesM.Clients === props.currentPage}
                clickHandler={() => props.viewClientListPreset(null)}
              >
                My Clients
              </TopBarMenuItem>
               <TopBarMenuItem
              isActive={RoutesM.Dashboard === props.currentPage}
              clickHandler={props.gotoDashboard}
            >
              Dashboard
            </TopBarMenuItem>
            <TopBarMenuItem
              isActive={RoutesM.Revenue === props.currentPage}
              clickHandler={props.gotoRevenue}
            >
              Revenue
            </TopBarMenuItem> 
            </TopBarMenu>
          )}

        <OnBoardingToolTip
          title="CONTROL YOUR TIME"
          body="Mark yourself Available to receive chats from your clients. Set yourself to Away when you need a break."
          step={4}
          placement="bottom-start"
          width="215px"
        >
          <div className="expert-area">
            <ExpertStatus />
            <a href={HELP_LINK} target="_blank" rel="noreferrer">
              <HelpWidgetIcon />
            </a>
            <SettingsButton
              disabled={props.isInSession}
              settingsClick={() => {
                setIsSettingsOpen(!isSettingsOpen);
              }}
            />
          </div>
        </OnBoardingToolTip>
        {isSettingsOpen && (
          <div className="settings-wrapper" ref={ref}>
            <Settings closeClick={() => setIsSettingsOpen(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => ({
  currentPage: state.router.location?.pathname,
  dashboardEnabled: dashboardEnabledSelector(state),
  isManagerOfVIPAccounts: isManagerOfVIPAccountsSelector(state),
  tutorialVideosUrl: state.appSettings.tutorialVideosUrl,
  isInSession: isInSessionSelector(state),
});

const connector = connect(mapState, {
  gotoRevenue:gotoRevenue,
  gotoDashboard: gotoDashboard,
  viewClientListPreset,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TopBar);
