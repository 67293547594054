import mixpanel from "mixpanel-browser";
import { userSelector } from "../selectors/userSelectors";
import { store } from "../store";
import { dateDiffInDays } from "../helpers/dateFormatHelper";
import { UserState } from "../types/userTypes";
import { isImpersonatedSelector } from "../slices/userSlice";

export enum TrackEvents {
  BecameAvailable = "became available",
  BecameUnavailable = "became unavailable",
  ChatBecameAvailable = "chat became available",
  ChatBecameUnavailable = "chat became unavailable",
  ChatRing = "chat ring",
  ChatAnswered = "chat answered",
  FavoritesUpdated = "favorites updated",
  ClientDetailsUpdated = "client details updated",
  ClientListLoaded = "client list loaded",
  ClientBlockedByAdvisor = "client blocked by advisor",
  NotesUpdated = "notes updated",
  TranscriptClicked = "transcript clicked",
  PubNubConnectionError = "pubnub connection error"
}

let defaultValues = {};

function init(projectId: string, userId: number | null, advisorId: number | null) {
  if (isImpersonated()) {
    return;
  }

  mixpanel.init(`${projectId}`);

  defaultValues = {
    "user type": "advisor",
    "advisor id": advisorId,
    "user id": userId,
    platform: "advisor web",
  };

  mixpanel.register(defaultValues);
}

function identify(identity: string) {
  if (isImpersonated()) {
    return;
  }
  mixpanel.identify(identity);
}

function trackEvent(event: TrackEvents, extra: any = {}) {
  if (isImpersonated()) {
    return;
  }

  const user: UserState = userSelector(store.getState());

  extra = { ...extra, ...defaultValues, "advisor modes": user.advisorModes };

  switch (event) {
    case TrackEvents.BecameAvailable:
    case TrackEvents.ChatBecameAvailable:
    case TrackEvents.BecameUnavailable:
    case TrackEvents.ChatBecameUnavailable:
    case TrackEvents.ChatRing:
    case TrackEvents.ChatAnswered:
      extra = addAdvisorInfo(addDateInfo(extra));
      break;
    default:
      break;
  }

  mixpanel.track(event, extra);
}
function reset() {
  if (isImpersonated()) {
    return;
  }
  
  mixpanel.reset();
}

const mixpanelService = {
  identify,
  trackEvent,
  reset,
  init,
  getDistinctId: () => {
    if (isImpersonated()) {
      return "";
    }

    return mixpanel.get_distinct_id();
  },
};
export default mixpanelService;

function addAdvisorInfo(extra: any): any {
  const user = userSelector(store.getState());
  return {
    ...extra,
    "credit balance": user.creditBalance,
    "current promotion": user.currentPromotion,
    "days since last purchase": user.lastPurchaseDate
      ? dateDiffInDays(new Date(user.lastPurchaseDate))
      : null,
    "email address": user.emailAddress,
    gender: user.gender,
    language: user.language,
    "last order date": user.lastOrderDate,
    "last purchase date": user.lastPurchaseDate,
    "order count": user.ordersCount,
    "purchases count": user.purchasesCount,
    "signup date": user.signupDate,
    "total purchase fees paid": user.totalPurchaseFeesPaid,
    "total purchased": user.totalPurchased,
  };
}

function addDateInfo(extra: any): any {
  const currentDate = new Date();

  return {
    ...extra,
    day: currentDate.getDate(),
    "day of week": currentDate.getDay(),
    "hour of day": currentDate.getHours(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
    "year-month": `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}`,
  };
}

const isImpersonated = () => {
  const isImpersonated: boolean = isImpersonatedSelector(store.getState());
  return isImpersonated;
}