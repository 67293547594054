import { AppState } from "../store";
import { ChatState, ChatExpertMessage} from "../types/chatTypes";

export const getIsClientTyping = (chatState: ChatState) =>
  chatState.isClientTyping;

// App State Selectors
export const chatSelector = (state: AppState) => state.chat;

export const isClientTypingSelector = (state: AppState) =>
  state.chat.isClientTyping;

export const realTimeMessageSelector = (state: AppState) =>
  state.chat.realTimeMessage;

export const getPrevMessages = (chatState: ChatState) =>
  chatState.prevMessages.map(messageId => chatState.messages[messageId]);

export const messagesSelector = (state: AppState) =>
  getPrevMessages(chatSelector(state));
  
export const getEditableMessage = (chatState: ChatState) => {
    return chatState.messages[chatState.editableMessage] as ChatExpertMessage;
  };
  
export const editableMessageSelector = (state: AppState) =>
  getEditableMessage(chatSelector(state));

export const submitFormFlagSelector = (state: AppState) => chatSelector(state).submitFormFlag;

export const filesSelector = (state: AppState) =>
  chatSelector(state)?.files;
  