import { select, takeEvery } from "redux-saga/effects";
import {
  reloadApp
} from "../slices/appSettingsSlice";
import { setAvailabilityRequest } from "../slices/userSlice";
import { userStatusSelector } from "../selectors/userSelectors";
import { ServerUserStatus } from "../types/userTypes";
import mixpanelService, { TrackEvents } from "../services/mixpanel";

function* handlerReloadApp() {
  
  const userStatus: ServerUserStatus = yield select(userStatusSelector);

  if (userStatus !== ServerUserStatus.Away) {
    mixpanelService.trackEvent(TrackEvents.BecameUnavailable);
    mixpanelService.trackEvent(TrackEvents.ChatBecameUnavailable);

    yield setAvailabilityRequest(ServerUserStatus.Away);
  }
  
  yield window.location.reload();
}

export default function* root() {
  yield takeEvery(reloadApp.type, handlerReloadApp);
}
