import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import createSagaMiddleware from "redux-saga";
import { createMemoryHistory } from "history";
import rootSaga from "./sagas";
import { userReducer } from "./slices/userSlice";
import { modalReducer } from "./slices/modalSlice";
import { sessionReducer } from "./slices/sessionSlice";
import { uiReducer } from "./slices/uiSlice";
import { chatReducer } from "./slices/chatSlice";
import { noteReducer } from "./slices/noteSlice";
import { clientListReducer } from "./slices/clientListSlice";
import { onBoardingReducer } from "./slices/onBoardingSlice";
import { appSettingsReducer } from "./slices/appSettingsSlice";
import { hubReducer } from "./slices/hubSlice";
import { showModal } from "./slices/modalSlice";
import { ModalType } from "./types/modalTypes";
import * as loggly from "./services/logger";
import { clientReducer } from "./slices/clientSlice";
import { dashboardReducer } from "./slices/dashboardSlice";
import { groupMessageReducer } from "./slices/groupMessageSlice";
import { authorizationReducer } from "./slices/authorizationSlice";

import {
    createMigrate,
    persistReducer,
    persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { Tuple, configureStore } from "@reduxjs/toolkit";
import { AxiosError, HttpStatusCode } from "axios";

const migrations = {
    "0": (state: any) => {

        const newState = { ...state };
        try {
            //remove old properties
            delete newState.dashboard.bi.lifecycleChart.firstSession.average;
            delete newState.dashboard.bi.lifecycleChart.secondSession.average;
            delete newState.dashboard.bi.lifecycleChart.thirdSession.average;
            delete newState.dashboard.bi.lifecycleChart.returning.average;
            delete newState.dashboard.bi.lifecycleChart.nonePaid.average;

            // add new properties
            newState.dashboard.bi.lifecycleChart.firstSession.clientsToPrevMonth = 0;
            newState.dashboard.bi.lifecycleChart.secondSession.clientsToPrevMonth = 0;
            newState.dashboard.bi.lifecycleChart.thirdSession.clientsToPrevMonth = 0;
            newState.dashboard.bi.lifecycleChart.returning.clientsToPrevMonth = 0;
            newState.dashboard.bi.lifecycleChart.nonePaid.clientsToPrevMonth = 0;
        } catch (error) {
            console.log(error);
        }

        return newState;
    },
    "1": (state: any) => {

        const newState = { ...state };
        try {
            //remove unused property
            delete newState.dashboard.bi.nonePaidChatsAvg;

        } catch (error) {
            console.log(error);
        }

        return newState;
    },

    "2": (state: any) => {

        const newState = { ...state, dashboard: {} };
        return newState

    }
};

const persistConfig = {
    key: "root",
    version: 2,
    storage: storage,
    whitelist: ["dashboard"],
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    migrate: createMigrate(migrations, { debug: false }),
};

const historyM = createMemoryHistory(); // or createBrowserHistory for URL support

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
    history: historyM //createMemoryHistory() //createBrowserHistory(),
    //other options if needed 
});

const rootReducer = combineReducers({
    router: routerReducer,
    user: userReducer,
    modal: modalReducer,
    session: sessionReducer,
    ui: uiReducer,
    chat: chatReducer,
    note: noteReducer,
    clientlist: clientListReducer,
    onBoarding: onBoardingReducer,
    appSettings: appSettingsReducer,
    hub: hubReducer,
    client: clientReducer,
    dashboard: dashboardReducer,
    groupMessage: groupMessageReducer,
    authorization: authorizationReducer,
});

const pReducer = persistReducer<ReturnType<typeof rootReducer>>(
    persistConfig,
    rootReducer
);

export type AppState = ReturnType<typeof rootReducer>;

export const store = configureStoreM();

export const history = createReduxHistory(store)

export const persistor = persistStore(store);

function configureStoreM() {
    const initialSagaMiddleware = createSagaMiddleware({
        onError: (err, erInfo) => {
            const axiosError = err as AxiosError;
            if (axiosError.isAxiosError) {
                if(axiosError.response?.status && [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden]
                    .includes(axiosError.response.status)) {
                    store.dispatch(showModal(ModalType.Unauthorized));
                } else {
                    store.dispatch(showModal(ModalType.ConnectionError));
                }
            } else {
                store.dispatch(showModal(ModalType.InternalError));
            }
            console.log(err, erInfo.sagaStack);
            loggly.error(err, {"Saga stack": erInfo.sagaStack});
        },
    });

    const middlewares = new Tuple(routerMiddleware, initialSagaMiddleware);

    // const composeEnhancers = composeWithDevTools(applyMiddleware(...middlewares));

    //const store = createStore(pReducer, composeEnhancers);

    const store:any = configureStore({
        reducer: pReducer,
        middleware: (/*getDefaultMiddleware*/) => middlewares
      });
      
    initialSagaMiddleware.run(rootSaga);
    
    return store;
}
