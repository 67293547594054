import { call, put, takeEvery } from "redux-saga/effects";
import { onBoardingFinish, onBoardingStart, showFirst } from "../slices/onBoardingSlice";
import { NUMBER_OF_ONBOARDING_TOOLTIPS, NUMBER_OF_TUTORIAL_SLIDES } from "../config";
import {
  saveLastViewedOnboardingTooltip,
  setShowOnboardingTooltips,
  saveLastViewedTutorialSlide
} from "../services/api/userApi";

function* handleOnBoardingStart() {
  yield put(showFirst());
}

function* handleOnBoardingFinish() {
  yield call(setShowOnboardingTooltips, false);
  yield call(saveLastViewedOnboardingTooltip, NUMBER_OF_ONBOARDING_TOOLTIPS);
  yield call(saveLastViewedTutorialSlide, NUMBER_OF_TUTORIAL_SLIDES); // mark feature slide as viewed for new users
}

export default function* root() {
  yield takeEvery(onBoardingStart.type, handleOnBoardingStart);
  yield takeEvery(onBoardingFinish.type, handleOnBoardingFinish);
}
